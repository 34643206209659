<template>
  <div v-loading="loading" class="order-detail-container">
    <div class="cont">
      <div class="buttonbc">
        <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
          <img src="../../../assets/back.png" alt="">
        </el-button>
      </div>
      <br />
      <h4>基本信息</h4>
      <table class="table-cont">
        <tbody>
          <tr>
            <td class="details-div-span">
              交易状态：
              <span class="detail-span1">{{ orderDetail.order_status_text }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                {{ orderDetail.hangup_status === 1 ? '(店铺挂起)' : (orderDetail.hangup_status === 2 ? '(企业挂起)' : (
    orderDetail.hangup_status === 3 ? '(供应商挂起)' : '')) }}
              </span>
            </td>
          </tr>
          <!--        下单人信息-->
          <tr>
            <td>下单人信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>下单人姓名：{{ orderDetail.member_nickname }}</td>
            <td>下单人手机号：{{ orderDetail.member_mobile }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>下单人所属分组：{{ orderDetail.group_name }}</td>
          </tr>
          <!--        订单信息-->
          <tr>
            <td>订单信息：</td>
          </tr>
          <tr class="detail-tr1">
            <td>用户下单时间：{{ orderDetail.create_time | unixToDate }}</td>
            <td>用户支付时间：<span v-if="orderDetail.order_status_text != '待付款'">{{ orderDetail.payment_time | unixToDate
                }}</span></td>
          </tr>
          <tr class="detail-tr1">
            <td>用户支付方式：
              <template v-if="orderDetail.mall_type == 7">站点账户余额</template>
              <template v-else>{{ orderDetail.payment_method_name }}</template>
            </td>

          </tr>
          <tr class="detail-tr1">
            <td>商城支付时间：<span v-if="orderDetail.order_status_text != '待付款'">{{ orderDetail.payment_time | unixToDate
                }}</span></td>
            <td>商城支付金额：<span v-if="orderDetail.order_status_text != '待付款'">{{ orderDetail.enterprise_order_price |
    unitPrice("¥")
                }}</span></td>
          </tr>
          <tr class="detail-tr1">
            <td v-if="orderDetail.is_combo !== 0">卡号：{{ orderDetail.card_code }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>企业支付时间：<span v-if="orderDetail.payment_time">{{ orderDetail.payment_time | unixToDate }}</span></td>
            <td>企业支付金额：{{ orderDetail.order_price | unitPrice("¥") }}</td>
          </tr>
          <!--     收货人信息   -->
          <tr>
            <td>收货人信息</td>
          </tr>
          <tr class="detail-tr1">
            <td>收货人：{{ orderDetail.ship_name }}</td>
            <td>收货人手机号：{{ orderDetail.ship_mobile }}</td>
          </tr>
          <tr class="detail-tr1">
            <td>
              收货地址：
              {{ orderDetail.ship_province }}{{ orderDetail.ship_city }}
              {{ orderDetail.ship_county }}{{ orderDetail.ship_town ? orderDetail.ship_town : '' }}
              {{ orderDetail.ship_addr }}
            </td>
          </tr>
          <tr v-if="orderDetail.is_combo !== 0">
            <td>备注信息：</td>
          </tr>
          <tr class="detail-tr1" v-if="orderDetail.is_combo !== 0" style="display:inline-block;max-width: 25vw">
            <td v-if="orderDetail.remark">{{ orderDetail.remark }}</td>
            <td v-else>无</td>
          </tr>
          <!--        订单来源信息-->
          <tr>
            <td>订单来源信息</td>
          </tr>
          <tr class="detail-tr1">
            <!-- <td>来源应用：{{ orderDetail.is_combo === 0 ? '现金商城' : '套餐卡商城' }}</td> -->
            <td>来源应用：{{ orderDetail.source }}</td>
            <td>商城名称：{{ orderDetail.shop_name }}</td>
          </tr>
          <tr v-if="cardInfoTh.card_type == 6 || cardInfoTh.card_type == 7">
            <td>卡券信息</td>
          </tr>
          <tr v-if="cardInfoTh.card_type == 6 || cardInfoTh.card_type == 7" class="detail-tr1">
            <td>卡券名称：{{ cardInfoTh.card_name }}</td>
            <td>卡券类型：{{ cardInfoTh.card_type == 6 ? '计次卡' : '宅配卡' }}</td>
            <td>卡号：{{ cardInfoTh.card_code }}</td>
            <td>卡券面值：{{ cardInfoTh.card_value }}</td>
          </tr>
          <tr v-if="cardInfoTh.card_type == 6 || cardInfoTh.card_type == 7" class="detail-tr1">
            <td v-if="cardInfoTh.card_type == 7">卡券配送周期：{{ cardInfoTh.available_times }}</td>
            <td>卡号所属公司：{{ cardInfoTh.company }}</td>
            <td>{{ cardInfoTh.card_type == 6 ? '兑换礼包名' : '配送礼包名称' }}：{{ cardInfoTh.sho_combo_name }}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <el-tabs v-model="propCurrentTab" type="card" @tab-click="GET_OrderDetail(propCurrentTab)">
        <el-tab-pane label="商品及物流信息" name="0">
          <h4>商品信息</h4>
          <template>
            <el-table :data="productList" :header-cell-style="{ background: '#cccccc' }" :expand-row-keys="rowKeysList"
              row-key="sku_sn">
              <el-table-column prop="goods_image" label="商品详情" width="300">
                <template slot-scope="scope">
                  <div style="display: flex; flex-direction: row; flex-wrap: nowrap;">
                    <img :src="scope.row.goods_image" class="goods-image" />
                    <el-tooltip class="item" effect="light" :content="scope.row.name" placement="top">
                      <el-button style="max-width: 19vw; overflow: hidden; text-overflow: ellipsis;" class="shop-name"
                        type="text">{{ scope.row.name }}</el-button>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="num" label="数量"></el-table-column>
              <el-table-column prop="price" label="平台售价">
                <template slot-scope="scope">
                  {{
    scope.row.original_price | unitPrice("￥")
  }}
                </template>
              </el-table-column>
              <el-table-column prop="shop_price" label="企业售价">
                <template slot-scope="scope">{{ scope.row.enterprise_purchase_price | unitPrice("￥") }}</template>
              </el-table-column>
              <el-table-column prop="enterprise_price" label="站点售价">
                <template slot-scope="scope">{{ scope.row.shop_purchase_price | unitPrice("￥") }}</template>
              </el-table-column>
              <el-table-column prop="shop_total_price" label="物流状态">
                <template slot-scope="{row}">
                  <span v-if="row.logistics_status_text">{{ row.logistics_status_text }}</span>
                  <span v-else>{{ row.state == 0 ? '未发货' : (row.state == 2 ? '已发货' : '已收货') }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="shop_total_price" label="维权状态">
                <template slot-scope="scope">{{ fService_status(scope.row.service_status) }}</template>
              </el-table-column>
            </el-table>
          </template>
          <br />
          <h4>包裹信息</h4>
          <el-tabs v-model="activeName" type="card">
            <el-tab-pane v-for="(item, index) in expressInfo" :key="index" :label="item.message" :name="item.index">
              <p style="background-color: #6666; height: 30px; line-height: 30px;">
                <span>快递公司: {{ item.deliver_name }}</span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span>物流单号: {{ item.delivery_no }}</span>
              </p>
              <div style="display: flex;">
                <div v-for="(i, index) in item.images" :key="index"
                  style="width: 100px; height: 100px; margin-right: 20px;">
                  <img :src="i" style="width: 100%; height: 100%;" />
                </div>
              </div>
              <div class="logistics-info" v-if="item.data.msg === 'JD'">
                <p v-if="!item.data.data.orderTrack" style="display:block; line-height: 20px;">{{
    item.data.data[0].express.message }}</p>
                <ul v-else>
                  <li v-for="(ship, index) in item.data.data.orderTrack" :key="index" class="ship-item">
                    <i class="ship-dot"></i>
                    <div class="ship-msg">
                      <p class="ship-msg-text">{{ ship.content }}</p>
                      <p class="ship-msg-time">{{ ship.msgTime }}</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="logistics-info" v-else>
                <p v-if="!item.data.data[0].express.data" style="display:block; line-height: 20px;">{{
    item.data.data[0].express.message }}</p>
                <ul v-else>
                  <li v-for="(ship, index) in item.data.data[0].express.data" :key="index" class="ship-item">
                    <i class="ship-dot"></i>
                    <div class="ship-msg">
                      <p class="ship-msg-text">{{ ship.context }}</p>
                      <p class="ship-msg-time">{{ ship.time }}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>
        <el-tab-pane label="历史备注信息" name="1">
          <en-table-layout :tableData="remarkList" :loading="loading">
            <template slot="toolbar">
              <el-form-item label="更新时间">
                <el-date-picker style="width: 200px" v-model="remarkParams.time_range" type="daterange"
                  :editable="false" unlink-panels size="small" range-separator="-" start-placeholder="开始日期"
                  end-placeholder="结束日期" @change="handleDateChange" :picker-options="{
    disabledDate(time) {
      return time.getTime() > Date.now();
    },
    shortcuts: MixinPickerShortcuts,
  }"></el-date-picker>
              </el-form-item>
              <el-form-item>
                <div style="margin: 0 8px">
                  <span>备注填写方：</span>
                  <el-select v-model="remarkParams.platform" placeholder="请选择">
                    <el-option v-for="item in platformOptions" :key="item.value" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item>
                <el-input style="width: 300px" v-model="remarkParams.operator" size="small" placeholder="请输入操作人名称进行搜索"
                  clearable></el-input>
              </el-form-item>
              <el-button @click="GET_OrderDetail(propCurrentTab)" size="small" type="primary"
                :disabled="loading">搜索</el-button>
            </template>
            <template slot="table-columns">
              <el-table-column label="备注信息">
                <template slot-scope="{row}">
                  <div v-html="row.remark.replaceAll('/n', '<br/>')"></div>
                </template>
              </el-table-column>
              <el-table-column label="备注填写方">
                <template slot-scope="{row}">
                  {{ row.platform === '1' ? '平台' : '供应商' }}
                </template>
              </el-table-column>
              <el-table-column prop="operator" label="操作人" width="300"></el-table-column>
              <el-table-column label="更新时间" width="300">
                <template slot-scope="{row}">{{ row.update_date | unixToDate }}</template>
              </el-table-column>
            </template>
            <el-pagination slot="pagination" @size-change="handlePageSizeChange"
              @current-change="handlePageCurrentChange" :current-page="remarkParams.page" :page-size="remarkParams.size"
              :total="remarkParams.data_total" :page-sizes="MixinPageSizes" :layout="MixinTableLayout"
              background></el-pagination>
          </en-table-layout>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as API_order from "@/api/order";
import * as Foundation from "@/../ui-utils/Foundation";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
export default {
  name: "orderDetail",
  components: { EnTableLayout },
  computed: {
    ...mapGetters(["shopInfo"])
  },
  data () {
    return {
      rowKeysList: [],
      /** 列表loading状态 */
      loading: false,
      /** 订单详情数据 */
      orderDetail: {},
      /**卡券详情 */
      cardInfoTh: '',
      productList: [],
      propCurrentTab: "0",
      // 订单备注列表
      remarkParams: {
        platform: '',
        sn: "",
        start: "",
        end: "",
        time_range: [],
        operator: "",
        page: 1,
        size: 10,
      },
      platformOptions: [{
        value: '',
        label: '全部'
      }, {
        value: '0',
        label: '供应商'
      }, {
        value: '1',
        label: '平台'
      }],
      remarkList: [],
      /*  订单编号 */
      sn: "",
      route: "",
      activeName: "1",
      /** 物流信息 */
      expressInfo: []
    };
  },
  filters: {
    paymentTypeFilter (val) {
      return val === "ONLINE" ? "在线支付" : "线下支付";
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.sn = to.params.sn;
    this.GET_OrderDetail(this.propCurrentTab);
    next();
  },
  mounted () {
    this.sn = this.$route.params.sn;
    sessionStorage.setItem('orderListActiveName', '1')
    this.GET_OrderDetail(this.propCurrentTab);
    // this.getExpressInfo()
    this.route = this.$route.path.split("/")[2];
  },
  activated () {
    this.sn = this.$route.params.sn;
    this.GET_OrderDetail(this.propCurrentTab);
  },
  methods: {
    fService_status (status) {
      switch (status) {
        case "NOT_APPLY":
          return "未申请";
        case "APPLY":
          return "已申请";
        case "PASS":
          return "审核通过";
        case "REFUSE":
          return "审核未通过";
        case "EXPIRED":
          return "已失效或不允许申请售后";
        case "REFUNDFAIL":
          return "退款失败";
        case "COMPLETED":
          return "完成";
        case "WAIT_SEND_BACK":
          return "待寄回";
        case "SEND_BACK":
          return "买家寄回";
        case "SELLER_SHIP":
          return "卖家发货";
        case "REFUSE_SHIP":
          return "卖家拒绝发货";
        default:
          return "";
      }
    },
    /** 获取订单详情信息 */
    GET_OrderDetail (propCurrentTab) {
      this.loading = true;
      if (propCurrentTab === "0") {
        API_order.getOrderChildrenDetail(this.sn)
          .then(res => {
            res.card_code && this.getOrdergetCardInfoTh(res);
            // 订单信息
            this.orderDetail = res;
            let cost_price = 0;
            this.orderDetail.order_sku_list.forEach(item => {
              cost_price += item.cost_price;
            });
            this.orderDetail["cost_price"] = cost_price;
            this.productList = this.orderDetail.order_sku_list;
            this.productList.forEach(item => {
              if (!item.state) {
                item.state = 0;
              }
            });
          })
          .then(() => {
            let params = {};
            params["order_sn"] = this.sn;
            params["sku_sn"] = this.productList[0]["sku_sn"];
            API_order.getOrderChildrenExpress(params).then(res => {
              this.loading = false;
              this.expressInfo = res;
              this.expressInfo.forEach((item, index) => {
                item["message"] = "包裹" + String(index + 1);
                item["index"] = String(index + 1);
              });
              console.log(this.expressInfo);
            });
          });
      } else {
        this.remarkParams.sn = this.sn;
        API_order.getRemarkList(this.remarkParams).then(res => {
          this.loading = false;
          this.remarkList = res.data;
          this.remarkParams.data_total = res.data_total;
          this.remarkParams.page = res.page_no;
          this.remarkParams.size = res.page_size;
        });
      }

    },
    /**查询订单详情卡券信息 */
    getOrdergetCardInfoTh (params) {
      API_order.getOrdergetCardInfoTh({ card_code: params.card_code, shop_id: params.shop_id }).then((res) => {
        this.cardInfoTh = res;
      })
    },
    getExpressInfo () {
      let params = {};
      params["order_sn"] = this.sn;
      params["sku_sn"] = this.productList[0]["sku_sn"];
      API_order.getOrderChildrenExpress(params).then(res => {
        this.expressInfo = res;
        this.expressInfo.forEach((item, index) => {
          item["message"] = "包裹" + String(index + 1);
          item["index"] = String(index + 1);
        });
        console.log(this.expressInfo);
      });
    },
    handleDateChange (val) {
      if (val) {
        this.remarkParams.start = parseInt(val[0].getTime() / 1000);
        this.remarkParams.end = parseInt(val[1].getTime() / 1000 + 86400);
      } else {
        this.remarkParams.start = "";
        this.remarkParams.end = "";
      }
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.remarkParams.size = size;
      this.GET_OrderDetail(this.propCurrentTab);
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.remarkParams.page = page;
      this.GET_OrderDetail(this.propCurrentTab);
    }
  }
};
</script>

<style lang="scss" scoped>
.logistics-info {
  background-color: #fff;
  padding: 10px 10px 50px 10px;

  // text-align: center;
  .ship-item {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    &:first-child {
      .ship-dot {
        background-color: #ec3a33;
      }

      p {
        color: #ec3a33;
      }
    }

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      z-index: 1;
      pointer-events: none;
      background-color: #e5e5e5;
      width: 1px;
      top: 37px;
      left: 10px;
      bottom: -20px;
    }
  }

  .ship-dot {
    position: relative;
    line-height: 20px;
    font-size: 10px;
    text-align: center;
    color: #666;
    margin: 24px 15px 0 5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ccc;
  }

  .ship-msg {
    flex: 1;
    padding: 15px 0;
  }

  .ship-msg-text {
    color: #333;
    font-size: 14px;
    line-height: 18px;
  }

  .ship-msg-time {
    font-size: 12px;
    color: #999;
  }
}

.table-cont {
  margin-bottom: 0;
  font-size: 13px;
  width: 100%;
  max-width: 100%;

  .detail-tr1 {
    font-size: 12px;
    color: #a6a6a6;
  }
}

.table-cont.order-table td {
  border-top: 0;
}

.table-cont>tbody>tr>td {
  line-height: 1.42857;
  padding: 8px;
  vertical-align: middle;
}

.m-l-md {
  margin-left: 20px !important;
}

.order-detail-container {
  background: #fff;
  position: relative;
  padding: 20px;
  border-radius: 5px;
}

.buttonbc {
  float: right;
  height: 40px;

  .back_btn {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #1A43A9;

    img {
      width: 10px;
      height: 20px;
      position: relative;
      top: -2px;
      left: -4px;
    }
  }
}

.cont {
  /* width: 1400px; */
  /* margin-left: 80px; */
  margin-left: 30px;
  padding-bottom: 20px;
}

::v-deep .is-leaf {
  /* border: 1px solid #000000 !important; */
  box-sizing: border-box;
  text-align: center;
  font-size: 16px;
}

::v-deep .el-table__row td {
  /* border: 1px solid #000000 !important; */
  text-align: center;
  font-size: 16px;
}

// ::v-deep .el-table--border {
//   /* border: 1px solid #000000 !important; */
// }
.zongji {
  width: 100%;
  font-size: 26px;
  text-align: right;
  padding-top: 30px;
}

.sendhuo {
  margin-top: 60px;
  float: right;
}

.goods-image {
  width: 50px;
  height: 50px;
}
</style>
